<template>
  <div class="footer">
    <div class="linsk-box">
      <div class="title">
        <div class="decorate">
          <svg-icon name="arrow-fill" class-name="pc" class="pc" />
          <svg-icon
            name="arrow-fill"
            class-name="w-1.3333vw h-6.4vw mobile"
            class="mobile"
          />
          <span>友情链接</span>
        </div>
      </div>
      <!-- 友情链接 -->
      <div class="linsk">
        <a
          :href="item.url"
          v-for="item in data.linkList"
          :key="item.id"
          target="_blank"
        >
          <div class="sign">
            <svg-icon name="down-arrow" />
          </div>
          <span>{{ item.title }}</span>
        </a>
      </div>
    </div>

    <div class="_footer">
      <div class="img-box">
        <div>
          <img src="@/assets/edp/images/footer/aacsb.png" alt="" />
          <img src="@/assets/edp/images/footer/amba.png" alt="" />
        </div>
      </div>
      <div class="contact-s">
        <div>
          <p>@中国科学院大学经济与管理学院版权所有</p>
          <p>地址：北京市海淀区中关村南一条3号 邮编：100190</p>
        </div>
      </div>
      <div class="footer-logo">
        <div>
          <svg-icon name="footer-logo" class="pc" />
          <svg-icon name="footer-logo" class="mobile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { apiLinks } from "@/api/footer";
import { onMounted, reactive } from "vue";

let data = reactive({
  linkList: [],
});

async function getLinks() {
  let result = await apiLinks();
  data.linkList = result.data;
}

function isElementInViewport(element) {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  // 判断元素的位置信息是否在可视区域范围内
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= windowHeight &&
    rect.right <= windowWidth
  );
}

onMounted(() => {
  let footer = document.querySelector(".img-box");
  const domImg = document.querySelector(".img-box > div"); // 图片
  const domText = document.querySelector(".contact-s > div"); // 文字
  const domLogo = document.querySelector(".footer-logo > div");
  getLinks();
  // 使用示例：
  window.addEventListener("scroll", () => {
    let isFooter = isElementInViewport(footer);
    if (isFooter) {
      domImg.className = "recovery1";
      domText.className = "recovery2";
      domLogo.className = "recovery3";
    } else {
      domImg.className = "";
      domText.className = "";
      domLogo.className = "";
    }
  });
});
</script>

<style lang="scss" scoped>
// 在pc端显示
@media screen and (min-width: $media) {
  .footer {
    width: 100%;
    height: 4.1172rem;
    background-color: #fff;
    background: url(~@/assets/edp/images/footer/footer.png) no-repeat;
    background-size: 100% 100%;
    padding-left: 1.2969rem;
    overflow: hidden;
    padding-top: 0.5938rem;
    // padding-right: 151px;

    .linsk-box {
      display: flex;
      .title {
        .decorate {
          display: flex;
          align-items: center;
          > span {
            display: inline-block;
            width: 1.1875rem;
            height: 0.2969rem;
            font-size: 0.2969rem;
            line-height: 0.2963rem;
            font-weight: 500;
            color: #ffffff;
            margin-left: 0.0781rem;
          }
        }

        .svg-box {
          width: 0.0781rem;
          height: 0.375rem;
        }
      }

      .linsk {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 0.9844rem;
        margin-left: 0.6016rem;
        > a {
          margin-right: 0.6797rem;
          font-size: .1563rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.2813rem;
          display: flex;

          .sign {
            transform: rotate(-90deg);
            opacity: 0;
            @include transition;

            .svg-box {
              width: .0938rem;
              height: .0938rem;
            }
          }

          &:hover {
            .sign {
              opacity: 1;
            }
          }
        }
      }
    }

    // 页脚
    ._footer {
      width: 100%;
      height: 2.1953rem;
      margin-top: 0.375rem;
      // border: 1px solid #ccc;
      padding-top: 0.6563rem;
      padding-bottom: 0.6563rem;
      display: flex;
      align-items: flex-end;
      > .img-box {
        height: 0.75rem;
        width: 3.8672rem;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          transform: translate(-0.4688rem, 0.4688rem);
          opacity: 0;
          @include transition;
          > img {
            width: 100%;
            height: 100%;
          }
          & img:last-child {
            margin-left: 0.0703rem;
          }
        }
      }

      .contact-s {
        margin-left: 0.3438rem;

        > div {
          display: flex;
          flex-direction: column;
          transform: translateY(0.4688rem);
          opacity: 0;
          @include transition;
          p {
            font-size: 0.1328rem;
            font-weight: 400;
            color: #ffffff;
            padding: 0.0313rem 0px;
          }
        }
      }

      .footer-logo {
        margin-bottom: -0.1563rem;
        margin-left: 1.1328rem;

        > div {
          transform: translate(0.4688rem, 0.4688rem);
          opacity: 0;
          @include transition;

          .svg-box {
            width: 4.1797rem;
            height: 0.8359rem;
          }
        }
      }
    }

    .recovery1 {
      opacity: 1 !important;
      transform: translate(0px, 0px) !important;
    }

    .recovery2 {
      opacity: 1 !important;
      transform: translateX(0px) !important;
    }

    .recovery3 {
      opacity: 1 !important;
      transform: translate(0px, 0px) !important;
    }
  }
}

// 在移动端显示
@media screen and (max-width: $media) {
  .footer {
    width: 100%;
    height: 101.2vw;
    background-color: #fff;
    background: url(~@/assets/edp/images/footer/mobile-footer.png) no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    .linsk-box {
      display: flex;
      height: 33.6vw;
      padding-left: 4.2667vw;
      padding-top: 6.4vw;
      .title {
        
        .decorate {
          display: flex;
          align-items: center;
          > span {
            white-space:nowrap;
            font-size: 5.0667vw;
            line-height: .78rem;
            font-weight: 500;
            color: #ffffff;
            margin-left: 1.3333vw;
          }

          .svg-box {
            width: 1.3333vw;
            height: 6.4vw;
          }
        }
      }

      .linsk {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 16.8vw;
        margin-left: 12.1333vw;
        > a {
          margin-right: 11.6vw;
          font-size: 2.4vw;
          font-weight: 400;
          color: #ffffff;
          line-height: 4.8vw;
          display: flex;

          .sign {
            display: none;
            transform: rotate(-90deg);
            margin-right: 10px;
            opacity: 1;
            @include transition;
          }

          > span {
            font-size: 2.4vw;
            font-weight: 400;
            color: #ffffff;
            line-height: 4.8vw;
          }
        }
      }
    }

    // 页脚
    ._footer {
      width: 100%;
      height: 67.6vw;
      margin-top: 5.8667vw;
      padding-left: 4.2667vw;

      > .img-box {
        height: 14.6667vw;
        width: 100%;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          opacity: 1;
          @include transition;
          > img {
            width: 35.8667vw;
            height: 100%;
          }
          & img:last-child {
            margin-left: 4.2667vw;
          }
        }
      }

      .contact-s {
        margin-top: 5.6vw;

        > div {
          display: flex;
          flex-direction: column;
          opacity: 1;
          @include transition;
          p {
            font-size: 2.9333vw;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 2.6667vw;
          }
        }
      }

      .footer-logo {
        margin-top: 8.6667vw;
        margin-left: -3.4667vw;

        > div {
          width: 10.22rem;
          height: 2.08rem;
          opacity: 1;
          @include transition;
        }
      }
    }

    .recovery1 {
      opacity: 1 !important;
      transform: translate(0px, 0px) !important;
    }

    .recovery2 {
      opacity: 1 !important;
      transform: translateX(0px) !important;
    }

    .recovery3 {
      opacity: 1 !important;
      transform: translate(0px, 0px) !important;
    }
  }
}
</style>
