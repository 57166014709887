<template>
  <template v-if="hasOneShowingChild">
    <MenuItem :name="props.item.id" :to="GoToOnePlace(props.item)">{{
      props.item.title_en
    }}</MenuItem>
  </template>

  <template v-else>
    <Submenu :name="props.item.id">
      <template #title> {{ props.item.title_en }} </template>

      <template v-for="(child, index) in props.item.children" :key="index">
        <MenuItem
          :name="child.article_id"
          v-if="!child.children"
          :to="GoToOnePlace(child)"
          >{{ child.title_en }}</MenuItem
        >

        <template v-else>
          <sidebar-item
            v-for="(route, index) in child.children"
            :key="index"
            :item="route"
          />
        </template>
      </template>
    </Submenu>
  </template>
</template>

<script>
export default {
  name: "sidebarItem",
};
</script>

<script setup>
import { computed } from "vue";
let props = defineProps({
  item: {
    type: Object,
  },
});

const hasOneShowingChild = computed(() => {
  if (props.item.template_type == 0 && !props.item.children) {
    return true;
  } else {
    return false;
  }
});

const GoToOnePlace = (params) => {
  return {
    path: `/edps/${params.vue_url}/${
      params.article_id ? params.article_id : params.tid
    }/${params.id}`,
  };
};
</script>

<script setup></script>

<style lang="scss"></style>
