<template>
  <Teleport to="#app">
    <div class="contact positionAbsolute pc" ref="contact">
      <div class="sign-up public">
        <svg-icon name="phone" class-name="w-46px h-45px" />
        <span>咨询电话</span>
        <div class="phoneNum" data-v-07b7f0a5="">
          <span data-v-07b7f0a5="">详情请咨询：</span><a href="javascript:;" data-v-07b7f0a5="">{{ phone }}</a>
        </div>
      </div>

      <div class="telephone public" @click="handler">
        <svg-icon name="chronicle" class-name="w-40px h-44px" />
        <span>在线报名</span>
      </div>
    </div>

    <div class="">
      <div class="mobile-box mobile">
        <div class="home-icon" @click="handlerEject" :style="{ transform: isOpen ? 'rotate(280deg)' : 'rotate(0deg)' }">
          <el-icon size="30" color="#fff">
            <Phone />
          </el-icon>
        </div>

        <div class="nav-icon" :style="calculation">
          <div class="contact_number" @click.stop="handlerNavList(1)">
            <el-icon color="#fff" size="25">
              <PhoneFilled />
            </el-icon>
            <span>咨询电话</span>
          </div>
          <div class="sign_up" @click.stop="handlerNavList(2)">
            <el-icon color="#fff" size="25">
              <Notebook />
            </el-icon>
            <span>在线报名</span>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { apiRealTimeInfo } from "@/api/home.js"
import { Plus, Notebook, PhoneFilled,Phone } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { onMounted, ref, onBeforeUnmount, nextTick, watchEffect, computed } from "vue";
let $router = useRouter();
let $route = useRoute()
const handler = () => {
  $router.push({
    path: "/edps/contactus/-1",
  });
};
const isMobile = function () {
  return window.innerWidth < 1200
}
let contact = ref(null);
let top;
let $noce = false
const fixedEle = () => {
  if (isMobile()) return
  let eleWidth = document.querySelector("#app > .zh");
  contact.value.style.right = eleWidth.offsetLeft / 128 + "rem";
};

const handlerScroll = () => {
  if (isMobile()) return
  let y = Math.floor(document.documentElement.scrollTop);
  if (top - y - 10 <= 0) {
    contact.value.className = "positionFixed contact pc";
  } else {
    contact.value.className = "positionAbsolute contact pc";
  }

  if ($noce) {
    if (y == 0) {
      contact.value.className = "positionAbsolute contact pc";
      $noce = false
      top = contact.value.getBoundingClientRect().top;
    }
  }
};

onMounted(() => {
  getRealTimeInfo()
  nextTick(() => {
    if (contact.value) {
      top = contact.value.getBoundingClientRect().top;
      window.addEventListener("scroll", handlerScroll);
    }
    fixedEle();
    window.addEventListener("resize", fixedEle);
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handlerScroll);
  window.removeEventListener("resize", fixedEle);
});

// 获取资讯电话
let phone = ref(null)
const getRealTimeInfo = async function() {
  let {data} = await apiRealTimeInfo()
  phone.value = data
}

watchEffect(() => {
  let path = $route.path
  $noce = true
})

// 手机端 start
let navIcon = ref(null)
let isOpen = ref(false)
const calculation = computed(() => {
  return {
    width: isOpen.value ? '6rem' : '0',
    opacity: isOpen.value ? 1 : 0
  }
})
const handlerEject = () => {
  isOpen.value = !isOpen.value
}

const handlerNavList = (num) => {
  switch (num) {
    case 1:
      window.location.href = 'tel:010-82680770'
      break;
    case 2:
      // 在线报名
      handler()
      break;
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: $media) {
  .positionFixed {
    position: fixed;
    top: 0.0781rem;
  }

  .positionAbsolute {
    position: absolute;
    top: 2.7422rem;
  }

  .contact {
    display: block;
    z-index: 1000;

    .public {
      width: 0.7813rem;
      height: 0.7813rem;
      background: linear-gradient(#ffbda5 0%, #e34f1b 92%, #de3a00 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      padding: 5px 10px;

      >span {
        width: 0.5625rem;
        height: 0.1406rem;
        font-size: 0.1406rem;
        font-weight: 500;
        color: #ffffff;
        margin-top: 0.1016rem;
      }

      .svg-box {
        width: 0.3594rem;
        height: 0.3516rem;
        @include transition;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .qr-code {
      margin-bottom: 0.1406rem;

      .share-qr-code {
        opacity: 1;
      }
    }

    .sign-up {
      position: relative;
      margin-bottom: 0.1406rem;
      @include transition;

      .phoneNum {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        position: absolute;
        top: 0px;
        right: 0.7813rem;
        height: 100%;
        width: 1.9531rem;
        background: #fff;
        padding-left: 0.1563rem;
        transform: scale(0);
        @include transition;


        >span {
          font-size: .125rem;
          color: $hover-color;
        }

        >a {
          font-size: .2188rem;
          color: $hover-color;
          @include transition;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &:hover {
        .phoneNum {
          transform: scale(1);
        }
      }
    }

    .telephone {
      opacity: 1;
      transform: scale(1);
      @include transition;
    }
  }
}

@media screen and (max-width: $media) {
  .mobile-box {
    position: relative;
    position: fixed;
    top: 85vh;
    right: .2rem;
    width: 2.4rem;
    height: 2.4rem;
    z-index: 1000;

    .home-icon {
      position: relative;
      z-index: 999;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2.4rem;
      background-color: $hover-color;
      transition: all 0.3s;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .nav-icon {
      position: absolute;
      z-index: 998;
      right: 1.2rem;
      top: 0px;
      width: 0px;
      height: 2.4rem;
      background-color: #FEBAA2;
      border-radius: 2.4rem 0px 0px 2.4rem;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: .5rem .7rem;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      >div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: .4rem;

        span {
          font-size: .44rem;
          color: #fff;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
