<template>
  <!-- 头部logo和input -->
  <!-- pc端导航栏 -->
  <div class="pc">
    <div class="herder">
      <div class="logo">
        <router-link :to="{ path: '/edp' }">
          <div class="svg-size">
            <svg-icon name="logo" class-name="svg-style" />
          </div>
        </router-link>
      </div>

      <div class="search">
        <div class="svg-box" @click="handlerKeySearch">
          <svg-icon name="search" />
        </div>
        <input type="text" placeholder="请输入搜索内容" v-model="data.searchContent" @keypress.enter="handlerKeySearch" />
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="nav-box">
      <div class="nav">
        <div class="li" v-for="item in data.navList" :key="item.id">
          <div class="content">
            <router-link :to="handlerParams(item)">{{
              item.title_en
            }}</router-link>
            <span class="instructions">
              <svg-icon name="down-arrow" class-name="icon-down" />
            </span>
          </div>
          <!-- 第二层导航 -->
          <div class="navigation" v-if="item.children">
            <div class="navigation-div" v-for="t in item.children" :key="t.id">
              <router-link :to="handlerParams(t)">{{ t.title_en }}</router-link>
              <!-- 第三层导航栏 -->
              <ul class="third_party" v-if="t.children && t.children.length > 0">
                <li v-for="three in t.children" :key="three.id">
                  <router-link :to="handlerParams(three)">{{
                    three.title_en
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 移动端导航栏 -->
  <div class="mobile">
    <div class="_header">
      <div class="_header_left">
        <router-link to="/edp">
          <!-- 在pc端显示 -->
          <svg-icon name="mobile-logo" />
        </router-link>
      </div>
      <div class="_header_right">
        <div class="_header_right_serach" @click="handlerGo">
          <svg-icon name="mobile-search" class-name="w-7.2vw h-7.2vw" />
        </div>
        <div class="_header_right_menu" @click="handlerOpenMenu">
          <svg-icon name="mobile-menu" class-name="w-7.6vw h-7.6vw" />
        </div>
      </div>
    </div>

    <div class="drawer" v-if="drawer">
      <el-drawer direction="ltr" size="57.2vw" v-model="drawer" lock-scroll :show-close="false" @close="handlerCloseMenu">
        <Menu accordion width="auto" @on-select="handlerSelect">
          <sidebar-item v-for="(item, index) in data.navList" :item="item" :key="index" />
        </Menu>
      </el-drawer>
    </div>
  </div>

  <Modal v-model="modalModal" :closabl="false" @on-cancel="modalModal = false" @on-ok="handlerModal">
    <div style="text-align:center;margin-top: 20px;">
      <Input v-model="data.searchContent" placeholder="请输入搜索内容" style="width: 300px" />
    </div>
  </Modal>
</template>

<script setup>
import sidebarItem from "./sidebarItem.vue";
import { reactive, onMounted, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { apiNavList } from "@/api/home.js";
let data = reactive({
  searchContent: "", // 搜索内容
  navList: [], //
});

// 回车搜索内容
let $router = useRouter();
const handlerKeySearch = () => {
  if (data.searchContent) {
    $router.push({
      path: "/edps/news/-3",
      query: {
        q: data.searchContent,
      },
    });
    data.searchContent = ''
  }
};
// 获取导航栏数据
const navList = async () => {
  let { code, data: res } = await apiNavList();
  if (code == 200) {
    data.navList = res;
  }
};

// 移动端 start
let drawer = ref(false); // 菜单是否打开
let searchBox = ref('')
let modalModal = ref(false)
const handlerOpenMenu = () => {
  // 打开遮罩层
  drawer.value = true;
  document.body.style.overflow = "hidden";
};
const handlerCloseMenu = () => {
  // 关闭遮罩层
  drawer.value = false;
  document.body.style.overflow = "";
};
const handlerSelect = () => {
  drawer.value = false;
  document.body.style.overflow = "";
};
const handlerGo = () => {
  modalModal.value = true
  drawer.value = false
}
const handlerModal = () => {
  modalModal.value = false
  nextTick(() => {
    $router.push({
      path: `/edps/news/427`,
      query:{
        q:data.searchContent
      }
    })
    data.searchContent = ""
  })
  searchBox.value = ''
}
// 移动端 end

onMounted(() => {
  navList();
});
// 跳转参数
let rootPath = "/edps";
const handlerParams = (par) => {
  let varEnum = ["detile", "user"];
  let url = {
    title: par.title_en,
    name: par.vue_url,
    params: {
      detailsId: varEnum.includes(par.vue_url) ? par.article_id : par.id,
    },
  };

  if (par.template_type == 0) {
    url = {
      title: par.title_en,
      // name: par.vue_url,
      path: `${rootPath}/${par.vue_url}/${varEnum.includes(par.vue_url) ? par.article_id : par.id
        }/${par.id}`,
    };
  } else {
    url = {
      title: par.children[0].title_en,
      // name: par.children[0].vue_url,
      path: `${rootPath}/${par.children[0].vue_url}/${varEnum.includes(par.children[0].vue_url)
        ? par.children[0].article_id
        : par.children[0].id
        }/${par.children[0].id}`,
    };
  }
  return url;
};
</script>

<style scoped lang="scss">
// @import 'view-ui-plus/dist/styles/viewuiplus.css';\
@import url('../../../style/iview.css');
// 当屏幕宽度大于
@media screen and (min-width: $media) {
  .herder {
    width: 100%;
    height: 1.25rem;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1.2969rem;

    .logo {
      width: 10.0625rem;
      height: 0.5703rem;

      .svg-size {
        width: 9.2813rem;
        height: 0.5859rem;
      }
    }

    .search {
      width: 1.9688rem;
      height: 0.2813rem;
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 0.1406rem;
      opacity: 1;
      border: 0.0078rem solid #de3a00;
      padding: 0.0625rem 0.0859rem;
      overflow: hidden;

      .svg-box {
        width: 0.1406rem;
        height: 0.1406rem;
      }

      >input {
        width: 100%;
        padding-left: 0.1016rem;
        border: none;
        outline: none;
        font-size: 0.1406rem;

        &::placeholder {
          color: #cbcbcb;
          font-size: 0.1406rem;
        }
      }
    }
  }

  .nav-box {
    width: 100%;
    height: 0.4375rem;
    background: url(~@/assets/edp/images/home/nav-bg.png) no-repeat;
    // background: url('../../../assets/edp/images/home/nav-bg.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 0px 0px 0px 0px;
    display: flex;

    .nav {
      width: calc(100% - 1.2969rem);
      display: flex;
      padding-left: 1.2969rem;

      >.li {
        display: flex;
        align-items: center;
        margin-right: 0.3047rem;
        position: relative;
        z-index: 1000;

        >.content {
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            font-size: 0.1875rem;
            font-weight: 400;
            color: #ffffff;
            margin-right: 0.1328rem;
          }

          .instructions {
            display: inline-block;
            width: 0.0703rem;
            height: 0.0703rem;
            @include transition;

            .icon-down {
              width: 100%;
              height: 100%;
            }
          }
        }

        .navigation {
          padding-top: 0.0625rem;
          position: absolute;
          z-index: 20;
          top: 0.4375rem;
          left: -40%;
          width: 1.5rem;
          background-color: #fff;
          @include hide;
          @include transition;

          .navigation-div {
            height: 0.4063rem;
            width: 100%;
            position: relative;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 0.4063rem;
              font-size: 0.1797rem;
              font-weight: 400;
              color: #de3a00;
            }

            &:hover>a {
              background: linear-gradient(#f5a88c 0%,
                  #e34f1b 92%,
                  #de3a00 100%);
              color: #fff;
            }

            // 第三层导航栏
            .third_party {
              position: absolute;
              width: 1.5rem;
              right: -100%;
              top: 0px;
              padding-left: 0.0391rem;

              >li {
                width: 100%;
                background-color: #fff;
                height: 0.4063rem;
                cursor: pointer;

                &:hover>a {
                  background: linear-gradient(#f5a88c 0%,
                      #e34f1b 92%,
                      #de3a00 100%);
                  color: #fff;
                }
              }

              @include hide;
              @include transition;
            }

            &:hover .third_party {
              @include displaying;
            }
          }
        }

        &:hover {
          .navigation {
            @include displaying;
          }

          .content>a {
            font-weight: 550;
          }

          .instructions {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 1.2969rem;
      background-color: #de3a00;
    }
  }
}

// 当屏幕宽度小于
@media screen and (max-width: $media) {
  ._header {
    display: flex;
    width: 100%;
    height: 2.02rem;
    background: linear-gradient(270deg, #ffbda5 0%, #e34f1b 61%, #de3a00 100%);

    &_left {
      display: flex;
      align-items: center;
      width: 10.96rem;
      height: 100%;
      padding-left: 0.52rem;

      .svg-box {
        width: 9.98rem;
        height: 0.62rem;
      }
    }

    &_right {
      flex: 1;
      display: flex;

      &_serach {
        width: 50%;
        height: 100%;
        background: #de3a00;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-box {
          width: 1.08rem;
          height: 1.08rem;
        }
      }

      &_menu {
        width: 50%;
        height: 100%;
        background: #a6291a;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-box {
          width: 1.14rem;
          height: 1.14rem;
        }
      }
    }
  }

  .drawer {
    position: absolute;
    left: 0px;
    top: 13.3333vw;
    right: 0;
    bottom: 0;
    z-index: 9999;

    &:deep(.el-overlay) {
      position: absolute;

      .el-drawer {
        background-color: #a6291a;

        // color: transparent;
        .el-drawer__header {
          display: none;
        }

        .el-drawer__body {
          padding: 6.4vw 5.0667vw;

          .ivu-menu {
            background-color: transparent;

            .ivu-menu-submenu-title,
            .ivu-menu-item-group-title {
              color: #fff;
              font-size: 3.8667vw;
              height: 13.3333vw;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #fff;
            }

            .ivu-menu-item {
              color: #fff;
              font-size: 3.8667vw;
              background-color: transparent;
              height: 13.3333vw;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #fff;

              &::after {
                content: "";
                display: none;
              }
            }

            &::after {
              content: "";
              display: none;
            }
          }
        }
      }
    }

    .menu {
      position: relative;
      width: 57.2vw;
      height: 141.4667vw;
      background: #a6291a;
    }
  }
}

.ivu-modal-content {

  &:deep(.ivu-modal-footer) {
    padding-bottom: 0px;

    .ivu-btn {
      background-color: #a6291a;
    }
  }
}
</style>
