import request from '@/utils/edp/request.js'

// 获取导航栏
export const apiNavList = () =>
  request({
    method: 'get',
    url: '/api/nav?is_en=3&is_banner_nav=1'
  })

// 获取轮播图数据
export const apiBannerList = () =>
  request({
    method: 'get',
    url: '/api/banner?is_en=3'
  })

// 内容数据
export const apiContent = () => {
  return request({
    method: 'get',
    url: '/api/edpIndex'
  })
}

// 获取资讯电话
export const apiRealTimeInfo = () => {
  return request({
    method: 'get',
    url: '/api/getEdpTel'
  })
}
